import { PrismicImage, PrismicRichText } from "./types";

export type TypeGet1On1Page = {
  title: string;
  meta_title: string;
  subtitle: PrismicRichText;
  hero_image: PrismicImage;
  page_meta_description: string;
  page_meta_thumbnail: PrismicImage;
};

export type TypeGet1On1QueryResult = {
  allPrismicGetA1On1Consultation: {
    edges: Array<{
      node: { data: TypeGet1On1Page };
    }>;
  };
};

export const getPage = (data: TypeGet1On1QueryResult): TypeGet1On1Page => {
  return data.allPrismicGetA1On1Consultation.edges[0].node.data;
};
