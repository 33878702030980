import { graphql, StaticQuery } from "gatsby";
import React from "react";
import "./book-1-on-1.scss";
import {
  getPage,
  TypeGet1On1Page,
  TypeGet1On1QueryResult,
} from "../../utils/queries/get_a_1-on-1_consultation";
import {
  PageHeaderCustomV2WhiteWide,
  PageHeaderStandard,
} from "../../components/page-header/page-header";
import Helmet from "react-helmet";
import { PageTitle } from "../../components/title/title";
import AmazeeIoMetadata from "../../components/metadata";
import AmazeePage from "../../components/layout";
import usePreviewData from "../../utils/usePreviewData";

const query = graphql`
  {
    allPrismicGetA1On1Consultation {
      edges {
        node {
          data {
            page_meta_description
            page_meta_thumbnail {
              alt
              url
            }
            title
            meta_title
            hero_image {
              alt
              url
            }
            subtitle {
              html
              raw
              text
            }
          }
        }
      }
    }
  }
`;

const Get1On1QueryPage = ({
  data,
  location,
}: {
  location: Location;
  data: TypeGet1On1QueryResult;
}) => {
  const pageData = getPage(data);
  if (!pageData) return null;
  const result = usePreviewData(
    pageData,
    "PrismicPricing",
    true
  ) as TypeGet1On1Page;
  const title = result.meta_title ? result.meta_title : result.title;

  return (
    <AmazeePage location={location}>
      <div id="one-on-one-conversation">
        <Helmet>
          <link rel="stylesheet" href="/css/carousel-single.min.css" />
          <link rel="stylesheet" href="/css/carousel-multi.min.css" />
        </Helmet>
        <PageTitle title={`${title} | amazee.io`} />
        <AmazeeIoMetadata
          title={result.title}
          page_meta_description={result.page_meta_description}
          page_meta_thumbnail={result.page_meta_thumbnail}
          location={location}
        />

        <PageHeaderStandard
          component={PageHeaderCustomV2WhiteWide}
          header={result.title}
          image={result.hero_image}
          subheader={result.subtitle}
          withSiteName={false}
          hasSectionDivider={false}
        />

        <section>
          <iframe
            title={"hubspot"}
            style={{
              width: "100%",
              height: "800px",
            }}
            src={
              "https://go.amazee.io/meetings/martin-schloegl/zeroops-conversation?embed=true"
            }
          />
        </section>
      </div>
    </AmazeePage>
  );
};

const StaticGet1On1QueryPage: React.FC<{ location: Location }> = ({
  location,
}) => {
  return (
    <StaticQuery
      query={query}
      render={(data) => <Get1On1QueryPage location={location} data={data} />}
    />
  );
};
export default StaticGet1On1QueryPage;
